import React from "react"
import { getTextKeyLabel } from "../../utils/text.util"
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import NewsArticle from "../news-article/NewsArticle"
import cx from "classnames"
import "./newsArticles.scss"

const NewsArticles = props => {
  const {
    filteredCategories = [],
    scrollToElement,
    backToCategoriesCta,
  } = props

  return filteredCategories.map((category, key) => (
    <section
      key={key}
      id={getTextKeyLabel(category?.category_label)}
      className={cx(
        "newsArticles",
        key % 2 ? "newsArticles__grey" : "newsArticles__white"
      )}
    >
      <div className="newsArticles_content">
        <div className="articleCategory">
          <HomeSubtitle category={category?.category_label}>
            {category?.category_title?.richText[0]}
          </HomeSubtitle>

          <p className="articleCategory_text">
            {category?.category_description}
          </p>
        </div>

        {category?.articles?.map((article, key) => (
          <NewsArticle
            key={`article-${key}`}
            imgSrc={article?.data?.article_image?.url}
            category={article?.data?.article_category}
            title={article?.data?.article_title}
            description={article?.data?.article_description}
            link={article?.data?.article_link?.url}
            actionLabel={article?.data?.article_cta_label}
          />
        ))}
      </div>

      <button
        className="categoryScrollButton"
        onClick={() => scrollToElement("categories")}
      >
        {backToCategoriesCta}
      </button>
    </section>
  ))
}

export default NewsArticles
