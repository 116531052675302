import React from "react"
import cx from 'classnames';
import { transformText } from "../../utils/text.util";
import "./homeSubtitle.scss"

const HomeSubtitle = (props) => {
  const {
    category,
    children,
    big,
    className,
  } = props;

  const renderText = () => {
    const text = transformText(children, "textAccent");
    return <h2 className={cx('homeSubtitle_text', big && 'homeSubtitle_text__big')}>{text}</h2>;
  }
  
  return (
    <div className={cx('homeSubtitle', className && className)}>
      {category?.length > 0 &&
        <div className="homeSubtitle_category">
          <span className="categoryText">{category}</span>
        </div>
      }
      {renderText()}
    </div>
  )
}

export default HomeSubtitle
