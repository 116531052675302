import React from "react"
import { transformText } from "../../utils/text.util"
import "./newsHero.scss"

const getCapitalizedCategory = category => {
  const categoryLower = category?.toLowerCase()
  return categoryLower?.charAt(0)?.toUpperCase() + categoryLower?.slice(1)
}

const NewsHero = props => {
  const { data, categories = [], categoriesRef = null, onCategoryClick } = props

  return (
    <div className="newsHero">
      <div className="newsHero_content">
        <section className="heroText">
          <h1 className="heroText_title">
            {transformText(data?.title?.richText[0], "titleAccent")}
          </h1>

          <div className="heroText_divider" />

          <p className="heroText_description">
            {transformText(data?.description?.richText[0], "descriptionStrong")}
          </p>
        </section>
        <section ref={categoriesRef} id="categories" className="heroCategories">
          <h2 className="heroCategories_title">Categorías</h2>
          {categories?.map((category, key) => (
            <div
              key={key}
              className="heroCategories_article"
              onClick={() =>
                category?.articles?.length > 0
                  ? onCategoryClick?.(category?.category_label)
                  : null
              }
            >
              {getCapitalizedCategory(category?.category_label)}
              {category?.articles?.length > 0 && (
                <img
                  className="articleArrow"
                  src="/img/arrow-right-accent.svg"
                  alt="arrow down"
                />
              )}
            </div>
          ))}
        </section>
      </div>
      <button
        className="newsHero_arrow"
        aria-label="Arrow down button"
      ></button>
    </div>
  )
}

export default NewsHero
