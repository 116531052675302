import React from "react"
import cx from "classnames";
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import ContactForm from "../form/ContactForm"
import { transformText } from "../../utils/text.util";
import "./formContact.scss"

const FormContact = (props) => {
  const {
    category,
    className,
    ctaText,
    description,
    id, 
    question,
    uid
  } = props;

  return (
    <section className={cx("formContact", className && className)} id={id}>
      <div className="formContact_content">
        <article className="formDescription">
          <HomeSubtitle category={category}>{question?.richText[0]}</HomeSubtitle>
          {description?.richText[0]?.text?.length > 0 && 
            <p className="formDescription_text">{transformText(description?.richText[0], "textStrong")}</p>}
        </article>
        <ContactForm
          className="formFields"
          buttonText={ctaText}
          uid={uid}
        />
      </div>
    </section>
  )
}

export default FormContact
