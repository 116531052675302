import * as React from "react"
import cx from "classnames"
import "./buttonAnchor.scss"

const ButtonAnchor = props => {
  const {
    accent,
    children = "",
    className,
    isButton,
    ...otherButtonProps
  } = props

  if (isButton) {
    return (
      <button
        {...otherButtonProps}
        className={cx(
          "buttonAnchor",
          accent && "buttonAnchor__accent",
          className && className,
        )}
      >
        <span className="buttonAnchor_text">{children && children}</span>
      </button>
    )
  }

  return (
    <a
      {...otherButtonProps}
      className={cx(
        "buttonAnchor",
        accent && "buttonAnchor__accent",
        className && className,
      )}
    >
      <span className="buttonAnchor_text">{children && children}</span>
    </a>
  )
}

export default ButtonAnchor
