import React, { useRef, useState } from "react"
import cx from "classnames"
import ButtonAnchor from "../button-anchor/ButtonAnchor"
import "./form.scss"

const ContactForm = props => {
  const { className, title, buttonText, uid } = props

  const contactForm = useRef(null)
  const [nameInvalid, setNameInvalid] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [universityInvalid, setUniversityInvalid] = useState(false)

  const runValidations = () => {
    setNameInvalid(false)
    setEmailInvalid(false)
    setUniversityInvalid(false)

    const formElements = contactForm.current.elements
    const nameValue = formElements.contactFormName.value
    const emailValue = formElements.contactFormEmail.value
    const universityValue = formElements.contactFormUniversity.value

    if (
      nameValue.length > 0 &&
      emailValue.length > 0 &&
      universityValue.length > 0
    ) {
      contactForm.current.submit()
    } else {
      if (nameValue.length === 0) {
        setNameInvalid(true)
      }
      if (emailValue.length === 0) {
        setEmailInvalid(true)
      }
      if (universityValue.length === 0) {
        setUniversityInvalid(true)
      }
    }
  }

  return (
    <div className={cx("wrapperForm", className && className)}>
      {title?.length > 0  && <div className="wrapperForm_title">
        <h2 className="mainTitle">{title}</h2>
      </div>}
      <form
        className="wrapperForm_content"
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={contactForm}
      >
        <div>
          <input type="hidden" name="form-name" value="contact" />
          <p className="hidden">
            <label>
              Don't fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
        </div>
        <div>
          <input type="hidden" name="url" value={uid} />
        </div>
        <div className={cx("formField", nameInvalid && "formField__error")}>
          <label className="formField_label" htmlFor="contactFormName">
            Nombres y Apellidos
          </label>
          <input
            className="formField_input"
            id="contactFormName"
            name="name"
            placeholder="Nombres y Apellidos"
            type="text"
          />
          {nameInvalid && <p className="formField_message">Error</p>}
        </div>
        <div
          className={cx("formField", universityInvalid && "formField__error")}
        >
          <label className="formField_label" htmlFor="contactFormUniversity">
            Universidad / Empresa
          </label>
          <input
            className="formField_input"
            id="contactFormUniversity"
            name="university"
            placeholder="Nombre de la institución"
            type="text"
          />
          {universityInvalid && <p className="formField_message">Error</p>}
        </div>
        <div className="formField">
          <label className="formField_label" htmlFor="contactFormPosition">
            Cargo <span className="optionalField">(opcional)</span>
          </label>
          <input
            className="formField_input"
            id="contactFormPosition"
            name="position"
            placeholder="Ingrese su rol o cargo en la institución"
            type="text"
          />
        </div>
        <div className={cx("formField", emailInvalid && "formField__error")}>
          <label className="formField_label" htmlFor="contactFormEmail">
            Correo electrónico
          </label>
          <input
            className="formField_input"
            id="contactFormEmail"
            name="email"
            placeholder="correo@dominio.com"
            type="email"
          />
          {emailInvalid && <p className="formField_message">Error</p>}
        </div>
        <div className="formField">
          <label className="formField_label" htmlFor="contactFormMessage">
            Dinos cómo podemos ayudarte
          </label>
          <textarea
            className="formField_input"
            id="contactFormMessage"
            name="text"
            placeholder="Déjanos tu mensaje"
            rows="4"
          />
        </div>
        <div className="formField">
          <input className="formField_checkbox" type="checkbox" id="contactFormAd" name="ad" />
          <label className="formField_checkboxLabel" htmlFor="contactFormAd">Me gustaría que me comuniquen las promociones y ofertas especiales.</label>
        </div>
        <div className="formButtons">
          <ButtonAnchor
            className="formButtons_button"
            onClick={() => runValidations()}
            isButton
            accent
          >
            {buttonText}
          </ButtonAnchor>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
