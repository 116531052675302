import { useEffect, useState } from "react"

const useIsElementVisible = ref => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    })

    ref?.current && observer.observe(ref.current)

    return () => {
      observer?.disconnect()
    }
  })

  return isVisible
}

export default useIsElementVisible
