import React from "react"
import "./newsArticle.scss"

const NewsArticle = props => {
  const {
    imgSrc = "",
    category = "",
    title = "",
    description = "",
    link = "#",
    actionLabel = "",
  } = props

  return (
    <a className="newsArticle" href={link} target="_blank" rel="noreferrer">
      {imgSrc && (
        <img
          className="newsArticle_image"
          src={imgSrc}
          loading="lazy"
          alt={title}
        />
      )}

      <div className="newsArticle_content">
        <label className="contentCategory">{category}</label>

        <h4 className="contentTitle">{title}</h4>

        {description && <p className="contentDescription">{description}</p>}

        <div className="contentDetails">
          {actionLabel}
          <span className="contentDetails_icon" />
        </div>
      </div>
    </a>
  )
}

export default NewsArticle
